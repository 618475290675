.ww-list-header {
  background: linear-gradient(
      0deg,
      rgba(220, 66, 66, 0.9),
      rgba(90, 5, 5, 1)
    ),
    url("./../../assets/pics/analytics7.jpg");
  height: 5rem;
  padding-top: 2rem;
  background-size: cover;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
}

.ww-list-header-title {
  font-family: Poppins, sans-serif;
  font-size: 4rem;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
}

.ww-list-header-subtitle {
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  text-align: center;
  color: #ddd;
  width: 50%;
  margin: auto;
  min-width: 30rem;
}
