@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");
.auth-outer-container {
	padding-top: 160px;
	padding-bottom: 120px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.user_message {
	border: red solid 2px;
	color: rgb(255, 161, 161);
	padding: 10px;
	position: absolute;
	top: 105px;
	font-size: 18px;
	font-weight: 700;
	z-index: 9999999;
	border-radius: 25px;
	background: rgba(255, 0, 0, 0.096);
	backdrop-filter: blur(5px);
}


.auth-container * {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Montserrat", sans-serif;
}

.auth-container {
	background-color: rgba(255, 255, 255, 0.04);
	border-radius: 15px;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
	position: relative;
	overflow: hidden;
	width: 768px;
	max-width: 100%;
	min-height: 520px;
	padding: 20px;
	backdrop-filter: blur(12px);
}

.auth-container p {
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.3px;
	margin: 20px 0;
}

.auth-container a {
	color: #333;
	font-size: 13px;
	text-decoration: none;
	margin: 15px 0 10px;
}

.auth-container button {
	background-color: #512da8;
	color: #fff;
	font-size: 12px;
	padding: 10px 45px;
	border: 1px solid transparent;
	border-radius: 8px;
	font-weight: 600;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	margin-top: 10px;
	cursor: none;
}

.auth-container button[type="submit"] {
	color: #ffffff;
	font-size: 15px;
	padding: 7px 45px;
	border: 1px solid transparent;
	border-radius: 500px;
	font-weight: 600;
	letter-spacing: 0.5px;
	margin-top: 10px;
	cursor: none;
	background-image: linear-gradient(
		to right,
		#4e3eff,
		#40dfe4,
		#30dd8a,
		#269660
	);
	background-size: 300% 100%;
	transition: all 0.3s;
	width: 300px;
}

.auth-container button[type="submit"]:hover {
	background-position: 100% 0;
	text-shadow: 0 0 10px white;
}

.auth-container button.hidden {
	background-color: transparent;
	border-color: #fff;
	border-radius: 500px;
}

.auth-container button:hover.hidden {
	border-color: transparent;
	box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.686);
}

.auth-container form {
	background-color: rgba(255, 255, 255, 0);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 40px;
	height: 100%;
}

.auth-container input {
	background-color: rgb(255, 255, 255, 0.04);
	border: none;
	border-radius: 500px;
	margin: 6px 0;
	padding: 10px 15px;
	font-size: 14px;
	font-weight: 600;
	width: 100%;
	outline: none;
	color: rgb(255, 255, 255);
}

.form-container {
	position: absolute;
	top: 0;
	height: 100%;
	transition: all 0.6s ease-in-out;
}

.form-container h1 {
	color: rgb(255, 255, 255);
	font-size: 25px;
	font-weight: 900;
}

.form-container input::placeholder {
	color: rgb(255, 255, 255);
	font-weight: 400;
}

.form-container input {
	height: 40px;
}

.form-container input[type="email"]:focus,
.form-container input[type="tel"]:focus,
.form-container input[type="text"]:focus,
.form-container input[type="password"]:focus {
	box-shadow: 0 0 5px rgba(255, 255, 255, 0.76);
}

.form-container input[type="number"]::-webkit-inner-spin-button,
.form-container input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.form-container input[type="number"] {
    -moz-appearance: textfield;
}

.form-container form .GoogleSignup{
	height: 40px;
	background: white;
	border-radius: 500px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 10px;
	cursor: none;
	transition: all 0.3s;
}

.form-container form .GoogleSignup:hover{
	box-shadow: 5px 5px 10px rgba(255, 255, 255, 0.2);
}

.form-container form .GoogleSignup p{
	text-transform: none;
	color: black;
	font-weight: 600;
	font-size: 16px;
}

.form-container form .GoogleSignup img{
	height: 30px;
	margin-right: 10px;
}


.sign-in {
	left: 0;
	width: 50%;
	opacity: 1;
	z-index: 2;
}

.auth-container.active .sign-in {
	transform: translateX(100%);
	opacity: 0;
}

.sign-up {
	left: 0;
	width: 50%;
	opacity: 0;
	z-index: 1;
}

.auth-container.active .sign-up {
	transform: translateX(100%);
	opacity: 1;
	z-index: 5;
	animation: move 0.6s;
}

@keyframes move {
	0%,
	49.99% {
		opacity: 0;
		z-index: 1;
	}
	50%,
	100% {
		opacity: 1;
		z-index: 5;
	}
}

.toggle-container {
	position: absolute;
	top: 0;
	left: 50%;
	width: 50%;
	height: 100%;
	overflow: hidden;
	transition: all 0.6s ease-in-out;
	border-radius: 60px 0 0 60px;
	z-index: 1000;
}

.auth-container.active .toggle-container {
	transform: translateX(-100%);
	border-radius: 0 60px 60px 0;
}

.toggle {
	height: 100%;
	background: linear-gradient(to right, #ad2d2d, #b16508);
	color: #fff;
	position: relative;
	left: -100%;
	height: 100%;
	width: 200%;
	transform: translateX(0);
	transition: all 0.6s ease-in-out;
}

.auth-container.active .toggle {
	transform: translateX(50%);
}

.toggle-panel {
	position: absolute;
	width: 50%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 30px;
	text-align: center;
	top: 0;
	transform: translateX(0);
	transition: all 0.6s ease-in-out;
}

.toggle-panel h1 {
	font-size: 25px;
	font-weight: 900;
}

.toggle-left {
	transform: translateX(-200%);
}

.auth-container.active .toggle-left {
	transform: translateX(0);
}

.toggle-right {
	right: 0;
	transform: translateX(0);
}

.auth-container.active .toggle-right {
	transform: translateX(200%);
}

@media (max-width: 790px) {
	.auth-container {
		border-radius: 12px;
		width: 600px;
		max-width: 100%;
		min-height: 500px;
		padding: 20px;
	}
}

@media (max-width: 420px) {
	.auth-container {
		border-radius: 12px;
		width: 350px;
		max-width: 100%;
		min-height: 500px;
		padding: 20px;
	}
	.toggle-container {
		transition: all 0.6s ease-in-out;
		border-radius: 20px 0 0 20px;
	}

	.auth-container.active .toggle-container {
		border-radius: 0 20px 20px 0;
	}
	.auth-container input {
		margin: 6px 0;
	}
	.form-container input {
		height: 28px;
	}
	.auth-outer-container {
		padding-top: 100px;
		padding-bottom: 80px;
	}
	.toggle-panel h1 {
		font-size: 20px;
		font-weight: 900;
	}
	.form-container form h1 {
		font-size: 20px;
	}
	.auth-container {
		width: 350px;
		max-width: 100%;
		min-height: 450px;
	}

	.auth-container button {
		padding: 8px 45px;
		text-wrap: nowrap;
	}

	.auth-container button[type="submit"] {
		color: #000000;
		font-size: 14px;
		padding: 5px 20px;
		font-weight: 600;
		font-style: normal;
		width: 160px;
	}

	.auth-container form {
		padding: 0 10px;
	}

	.auth-container input {
		font-size: 12px;
		font-weight: 600;
	}
	.user_message {
		padding: 8px;
		top: 95px;
		font-size: 15px;
		border-radius: 25px;
		background: rgba(255, 0, 0, 0.2);
		backdrop-filter: blur(10px);
	}
}
