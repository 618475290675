.certificate-search {
  display: flex;
}

.certificate-search form {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.certificate-search input {
  margin: 1rem auto;
  font-size: 1.5rem;
  font-family: "Ubuntu", "Poppins", sans-serif;
  text-align: center;
  padding: 1rem;
  border-radius: 10px;
}

.certificate-search input[type="email"] {
  border-width: 0;
  border-color: white;
}

.certificate-search input[type="submit"] {
  background-color: lightcoral;
  display: inline-block;
  width: 100%;
  max-width: 15rem;
  border-color: white;
  border-width: 3px;
  border-style: solid;
  cursor: pointer;
}
