.kdag-nav {
  position: fixed;
  z-index: 50;
  width: 100%;
  transition: background-color 200ms linear;
  backdrop-filter: blur(20px);
}

.kdag-nav.scrolled {
  background-color: transparent;
  transition: background-color 200ms linear;
  box-shadow: rgba(0, 0, 0, 0.7) 2px 0 10px 4px;
}

.kdag-nav-contain {
  display: flex;
  justify-content: space-around;
  width: 95%;
  margin: auto;
  position: relative;
  z-index: 40;
  align-items: center;
}

.kdag-nav-contain-border {
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}

.kdag-nav-items {
  display: flex;
  width: 40%;
  justify-content: space-between;
  max-width: 40rem;
  align-items: normal;
  color: rgba(255, 255, 255, 0.4);
}

.kdag-nav-item:hover{
  color: red;
  -webkit-text-stroke: 0px;
  text-shadow: 0 0 12px red;
}

.kdag-nav-logo {
  margin: 0.5rem 2rem 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #dc2626;
  /* TODO: remove later */
  font-size: 2rem;
  height: 100%;
  padding-bottom: 0.5rem;
  text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.9);
}

.kdag-nav-logo img {
  width: 18rem;
  height: 3.7rem;
  /* border-radius: 5%; */
  /* box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.9); */
}

.kdag-navbar-kdag {
  width: 20rem;
  font-size: 1rem;
  display: inline-block;
  font-family: "Poppins", sans-serif;
  margin-left: 0.5rem;
  font-weight: 900;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.95)
}

/* TODO: remove later */
.kdag-nav-logo a {
  text-decoration: none;
  color: white;
  display: flex;
  align-items: center;
}

.kdag-nav-item {
  font-size: 1.3rem;
  margin: 1rem 0;
  /* color: black; */
  -webkit-text-stroke: 1px #c2c2c2;
}

.kdag-nav-item::after{
  content: "";
  background: rgb(255, 0, 0);
  border-radius: 2px;
  height: 2px;
  width: 100%;
  z-index: 99999;
  left: -50%;
  top: 0px;
  position: relative;
  display: block;
  box-shadow: 0 0 10px red;
  transition: all 0.2s;
  opacity: 0;
  transform: scaleX(0);
}

.kdag-nav-item img{
  height: 35px;
}

.kdag-nav-item:hover::after {
  transform: scaleX(1);
  left: 0;
  opacity: 1;
}

.kdag-nav-item a {
  color: inherit;
  text-decoration: none;
}

.kdag-nav-mobile ul {
  -webkit-font-smoothing: antialiased;
  background: rgba(255, 0, 0, 0);
  list-style: none;
  margin: 0;
  padding: 0;
}

.kdag-nav-mobile-ul li ul li img{
  height: 35px;
}

.kdag-nav-mobile li {
  float: left;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}

.kdag-nav-mobile a {
  background: rgb(0, 0, 0);

  color: #8794ba;
  display: block;
  font: bold 16px/50px sans-serif;
  padding: 0 25px;
  text-align: center;
  text-decoration: none;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;

  backdrop-filter: blur(5px);
  border-radius: 15px;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.508);
}

.kdag-nav-mobile .dropdown:after {
  content: " ▶";
}

.kdag-nav-mobile .dropdown:hover:after {
  content: "\25bc";
}

.kdag-nav-mobile li:hover a {
  background: rgba(0, 0, 0, 0.7);
}

.kdag-nav-mobile li ul {
  float: right;
  right: 0;
  opacity: 0;
  position: absolute;
  top: 35px;
  visibility: hidden;
  z-index: 1;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
  width: 20rem;
  box-shadow: 1px 1px 15px 10px rgba(0, 0, 0, 0.5)
}

.kdag-nav-mobile li:hover ul {
  opacity: 1;
  top: 50px;
  visibility: visible;
}

.kdag-nav-mobile li ul li {
  float: none;
  width: 100%;
  color: white;
}

.kdag-nav-mobile li ul a:hover {
  background: #ffffff25;
  width: 100%;
}

.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
}


/* Clearfix */

/* .cf:after,
.cf:before {
  content: "";
  display: table;
}
.cf:after {
  clear: both;
}
.cf {
  zoom: 1;
  width: 350px;

  background: transparent;
} */

.hamburgermenu {
  width: 40px;
  height: 40px;
}
 
.drop {
  display: none;
  text-align: right;
}

@media (max-width: 740px) {
  .kdag-nav-item {
    display: none;
  }

  /* .kdag-nav-logo {
    position: absolute;
    margin-left: 90%;
  } */
  .drop {
    display: block;
  }

  .kdag-nav-mobile a {
    padding: 2px;
    margin-top: 3px;
  }

  /* .cf {
    width: 520px;
  } */
}

@media (max-width: 570px) {
  /* .kdag-nav-logo {
    position: absolute;
    margin-left: 85%;
  } */
  /* .cf {
    width: 450px;
  } */
}

@media (max-width: 440px) {
  /* .kdag-nav-logo {
    position: absolute;
    margin-left: 80%;
  } */
  /* .cf {
    width: 350px;
  } */
}

@media (max-width: 340px) {
  /* .kdag-nav-logo {
    position: absolute;
    margin-left: 75%;
  } */
  /* .cf {
    width: 300px;
  } */
}

@media (max-width: 275px) {
  /* .kdag-nav-logo {
    position: absolute;
    margin-left: 70%;
  } */
  /* .cf {
    width: 285px;
  } */
}