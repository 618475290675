@import url("https://fonts.googleapis.com/css?family=Poppins:400,500");

:root {
  --black: #000000;
  --gray: #4a4a4a;
  --lightgray: #f4f4f4;
  --blue: #407bff;
  --white: #ffffff;
}

html {
  font-size: 100%;
}

.winter-workshop-session-container {
  width: 100%;
  height: auto;
  margin-top: 3rem;
}

.winter-workshop-session-heading {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin: 4px 0px 6px 0px;
  flex-wrap: wrap;
}

.winter-workshop-session-heading-in {
  display: flex;
  flex-wrap: wrap;
}

.winter-workshop-session-name {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  color: var(--black);
}

.winter-workshop-session-time {
  margin-left: 0.5rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  color: var(--gray);
  display: flex;
  align-items: center;
}

.winter-workshop-session-recording {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0px;
  border-radius: 0.5rem;
  background-color: var(--blue);
  border: none;
  color: white;
  text-decoration: none;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  padding: 1rem;
  font-size: 1.125rem;
  flex-grow: 1;
  flex-basis: 0;
  flex-shrink: 1;
  margin-bottom: 1rem;
}

.recording-icon {
  margin-right: 0.25rem;
  width: 1.5rem;
  height: 1.5rem;
}

.winter-workshop-topic-container {
  align-items: center;
  background-color: var(--lightgray);
  border-radius: 0.5rem;
  display: flex;
  height: 3.125rem;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 1rem;  
}

.winter-workshop-topic-div {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  margin-left: 1.125rem;
  min-height: 1.75rem;
  width: 100%;
  /* padding: 1rem; */
}
