.member-card-wrapper-SM {
  margin: 1rem;
  width: 50%;
  max-width: 20rem;
} 

.member-card-SM {
    padding: 1rem;
    box-shadow: 2px 2px 5px 1px #777;
    border-radius: 5px; 
    transition: all .3s ease;
    display: flex;
    align-items: center;
  }
  
  .member-card-SM:hover {
    transform: scale(1.05);
    box-shadow: 2px 2px 8px 2px #777;
  }
  
  .member-profile-image-SM {
    width: 3rem;
    margin: auto;
    margin-right: 1rem;
    height: 3rem;
    overflow: hidden;
    border-radius: 100px;
    object-fit: cover;
    /* margin-bottom: 1rem; */
  }
  
  .member-profile-image-SM img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .member-name-SM {
    font-size: 1.2rem;
    text-align: left;
    flex-grow: 1;
    color: #fff;
  }
  
  .member-email-SM {
    color: #777;
    text-align: center;
    /* margin-bottom: 2rem; */
  }
  
  .member-profile-follow-SM {
    color: #777;
    display: flex;
    justify-content: right;
    text-align: right;
    flex-grow: 1;
  }
  
  .member-social-icon-SM {
    display: flex;
    justify-content: right;
    align-items: center;
    flex-grow: 1;
    width:  100%;
  }
  
  .member-social-icon-icon-SM {
    padding-left: 1rem;
  }
  
  .member-social-icon-icon-SM a {
    text-decoration: none;
    color: inherit;
  }
  
  .member-social-icon-icon-SM:hover {
    color: #F53D3D;
  }
  
  
  