.winter-workshop-resources {
  padding: 1.5rem;
}

.winter-workshop-blogs-head {
  font-size: 1.2rem;
  font-weight: bold;
}

.winter-workshop-container {
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}

.winter-workshop-blog-box {
  background-color: aquamarine;
  padding: 0.5rem 1rem;
  border-radius: 5px;;
  margin: 1rem;
  color: white;
  box-shadow: 1px 1px 10px 2px rgba(0, 0, 0, 0.3);
  width: 20rem;
}

.winter-workshop-blog-head-topic {
  font-size: 1.2rem; 
  color: white;
}

.winter-workshop-blog-topic {
  font-size: 0.8rem;
  margin-bottom: 2rem;
}
