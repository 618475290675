:root
{
    --primary: #d80f08ec;
}

.btn
{
   padding-top: 8px;
   padding-bottom: 8px;
   padding-left: 20px;
   padding-right: 20px;
   /* border-radius: 20 px !important; */
   outline: none;
   border: none;
   font-size: 18px;
   color: azure;
   cursor: pointer;
   background-color: var(--primary);
   transition: all 5s easeout;
}
.btn:hover {
    background-color: transparent;
    color: azure;
    border-radius: 4px;
    border: 2px solid var(--primary);
}