.header-container {
  position: relative;
  z-index: 10;
  display: flex;
  width: 100%;
  padding: 3rem;
}

.header-title {
  width: 50%;
  padding: 1rem 0;
  position: relative;
  z-index: 10;
  min-width: 300px;
}

.header-title-logo {
  text-align: center;
  margin: 1rem;
}

.header-title-logo img {
  width: 7rem;
  border-radius: 5%;
  box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0.7);
}

.header-title-text {
  text-transform: uppercase;
  font-size: 3.5rem;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  font-family: "Poppins", "Roboto", sans-serif;
  text-shadow: 10px 10px 30px rgba(0, 0, 0, 0.9);
}

.header-title-text span {
  color: #dc2626;
}

.header-graphics {
  width: 50%;
  position: relative;
  min-width: 300px;
}

.header-image {
  width: 100%;
  position: absolute;
  right: 0;
}

.stripe {
  position: absolute;
  width: 200%;
  height: 20rem;
  z-index: 1;
  background-color: #172755;
  top: 60%;
  transform: translate(-25%) rotate(-6deg);
  border-bottom: 1rem solid #dc2626;
}

.random-svg-1 {
  position: absolute;
  top: 0;
  left: 10%;
  z-index: 5;
}

.random-svg-2 {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
  opacity: 10%;
}

@media (max-width: 800px) {
  .header-graphics {
    display: None;
  }

  .header-title {
    width: 100%;
    /* padding: 1rem 0;
    position: relative;
    z-index: 10;
    min-width: 300px; */
  }
}

@media (max-width:  1200px) {
  .stripe {
    top: 75%;
  }
}
