.blog-list-card {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  margin: 2rem;
  transition: all .3s ease;
  height: 95%;
  background-color: rgba(255, 255, 255, 0.062);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 12px;
  /* border: red solid 20px; */
}

.blog-list-card:hover  {
  transform: scale(1.02);
  background: white;
}

.blog-list-card:hover .blog-list-card-title{
  color: black;
}

.blog-list-card:hover .blog-list-card-description{
  color: black;
}

.blog-list-card:hover .blog-list-card-tag{
  color: #44a200;
}

.blog-list-card-image {
  width: 100%;
}

.blog-list-card-image img {
  width: 100%;
  height: 15rem;
  object-fit: cover;
  border-radius: 15px;
}

.blog-list-card-text {
  padding: 1rem;
}

.blog-list-card-topic {
  color: #bfbfbf;
  font-family: Nunito, sans-serif;
  font-size: 1rem;
  font-weight: 700;
}

.blog-list-card-title {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0.5rem 0;
  transition: all 0.3s;
}

.blog-list-card-author {
  font-family: Poppins, sans-serif;
  font-size: 0.8rem;
}

.blog-list-card-authorname {
  color: #dc2626;
}

.blog-list-card-date {
  color: #bfbfbf;
  font-size: 0.9rem;
}

/* .blog-list-card hr {
  height: 1px;
  border-width: 0;
  color: #aaa;
  background-color: #aaa;
  margin: 0.8rem 0;
} */

.blog-list-card-description {
  color: #bfbfbf;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  transition: all 0.3;
}

.blog-list-card-tags {
  margin: 1rem 0;
  font-family: Poppins, sans-serif;
  font-size: 0.9rem;
  color: #ff3a3a;
  font-weight: 900;
}

.blog-list-card-tag {
  display: inline-block;
  margin: 0.2rem 0.2rem;
  /* background-color: #5f5f5f; */
  padding: 0.2rem 1rem;
  padding-left: 0;
  border-radius: 2px;
  color: #d1ff19;
  font-weight: 500;
  font-size: 0.8rem;
}

.blog-list-card-link {
  text-decoration: none;
  display: block;
  width: 25%;
  min-width: 31rem;
}
