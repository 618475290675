.member-card-wrapper-head {
	width: 50%;
	max-width: 15rem;
	margin: 2rem;
}

.member-card-head {
	padding: 2rem;
	border-radius: 5px;
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
	width: 100%;
	background: rgba(255, 255, 255, 0.042);
	backdrop-filter: blur(3px);
	font-weight: 500;
	box-shadow: 
		0px 0px 0 1px rgb(255, 255, 255),
		07px -07px 0 -1px black,
		07px -07px yellow, 
		14px -14px 0 -1px rgb(0, 0, 0),
		14px -14px 0 green,
		21px -21px 0 -1px rgb(0, 0, 0),
		21px -21px 0 red;
	transition: all 0.7s, box-shadow 1s, top 1s, left 1s;
}

.member-card-head:hover {
	background: white;
	border-radius: 5px;
	border-top-right-radius: 150px;
	border-top-left-radius: 5px;
	width: 105%;
	height: auto;
	box-shadow: 
		0px 0px 0 1px rgb(255, 255, 255),
		15px -15px 0 -1px black,
		15px -15px rgb(255, 255, 255), 
		-15px 15px 0 -1px rgb(0, 0, 0),
		-15px 15px 0 rgb(255, 255, 255),
		0px 0px 0 0px rgb(0, 0, 0),
		0px 0px 0 rgb(255, 255, 255);
}

.member-card-wrapper {
	width: 100%;
	margin: 1.5rem;
	margin-right: 20px;
}

.member-card {
	padding: 1.5rem;
	border-radius: 5px;
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
	transition: all 0.3s ease;
	width: 90%;
	margin-right: 50px;
	background: rgba(255, 255, 255, 0.042);
	backdrop-filter: blur(3px);
	font-weight: 500;
	box-shadow: 
		0px 0px 0 1px rgb(255, 255, 255),
		15px -15px 0 -1px black,
		15px -15px rgb(255, 255, 255), 
		-15px 15px 0 -1px rgb(255, 255, 255),
		-15px 15px 0 rgb(255, 255, 255);
	transition: all 0.7s, box-shadow 1s, top 1s, left 1s;
}

.member-card:hover {
	background: white;
	border-top-right-radius: 150px;
	width: 80%;
	box-shadow: 
	0px 0px 0 1px rgb(255, 255, 255),
	20px -20px 0 -1px black,
	20px -20px rgb(255, 255, 255), 
	-20px 20px 0 -1px black,
	-20px 20px 0 rgb(255, 255, 255);
}

.member-card:hover p {
	color: black;
	border-top-right-radius: 50px;
	font-weight: 800;
}

.member-card-head:hover p {
	color: black;
	border-top-right-radius: 50px;
	font-weight: 800;
}

.member-profile-image {
	position: relative;
	margin: auto;
	width: 10rem;
	height: 10rem;
	overflow: hidden;
	border-radius: 200px;
	margin-bottom: 1rem;
	top: 0;
	left: 0;
	transition: all 0.8s;
}

.member-profile-image-head {
	position: relative;
	margin: auto;
	width: 10rem;
	height: 10rem;
	overflow: hidden;
	border-radius: 200px;
	margin-bottom: 1rem;
	top: 0px;
	left: 0px;
	transition: all 0.8s;
}

.member-card:hover .member-profile-image {
	height: 14rem;
	width: 14rem;
	top: 0px;
	left: -45px;
	border-radius: 150px;
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
	box-shadow: 5px 5px 10px rgb(60, 60, 60);
}

.member-card-head:hover .member-profile-image-head {
	height: 14rem;
	width: 14rem;
	top: 0px;
	left: -50px;
	border-radius: 150px;
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
	box-shadow: 5px 5px 10px rgb(60, 60, 60);
}

.member-profile-image img {
	width: 100%;
}

.member-profile-image-head img {
	width: 100%;
}

.member-name {
	font-size: 1.2rem;
	text-align: center;
	color: #fff;
	height: 65px;
	position: relative;
	bottom: -40px;
	transition: all 0.5s;
}

@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');

.member-name-head {
	font-size: 1.5rem;
    text-align: center;
    color: #fff;
    height: 65px;
    position: relative;
    bottom: 0px;
    transition: all 0.5s;
    font-family: 'Satisfy', cursive;
    line-height: 25px;
}

.member-card:hover .member-name {
	bottom: 0;
}

.member-card-head:hover .member-name {
	bottom: 0;
}

.member-email {
	color: #777;
	text-align: center;
	margin-bottom: 2rem;
	margin-left: -25px;
	margin-top: 15px;
	white-space: wrap;
}

.member-profile-follow {
	color: #777;
	display: flex;
	justify-content: space-between;
	position: relative;
	bottom: -10px;
	transition: all 0.3s;
	opacity: 0;
}

.member-follow-text {
	padding-left: 15px;
}

.member-card:hover .member-profile-follow {
	opacity: 1;
	transform: scale(1.2);
}

.member-card-head:hover .member-profile-follow {
	opacity: 1;
	transform: scale(1.2);
}

.member-social-icon {
	display: flex;
	padding-right: 15px;
}

.member-social-icon-icon {
	padding-left: 0.8rem;
}

.member-social-icon-icon a {
	text-decoration: none;
	color: inherit;
}

.member-social-icon-icon:hover {
	color: #f53d3d;
}

@media screen and (max-width: 1200px) {
	.member-profile-follow {
		opacity: 1;
	}
	.member-card:hover {
		border-top-right-radius: 120px;
	}
	.member-card:hover .member-profile-image {
		top: 0px;
		left: -35px;
	}
}

@media screen and (max-width: 1000px) {
	.member-card-wrapper {
		width: 50%;
		max-width: 15rem;
		margin: 1.4rem;
	}
	.member-card {
		padding: 2rem;
		border-radius: 5px;
		border-top-right-radius: 5px;
		border-top-left-radius: 5px;
		transition: all 0.3s ease;
		width: 90%;
		background: rgba(255, 255, 255, 0.042);
		backdrop-filter: blur(3px);
		transition: all 0.7s;
		font-weight: 500;
		box-shadow: 0 0 2px rgba(255, 255, 255, 0.35);
	}
	.member-card-head {
		box-shadow: 0px 0px 0 1px rgb(255, 255, 255), 06px -06px 0 -1px black,
		06px -06px yellow, 12px -12px 0 -1px rgb(0, 0, 0), 12px -12px 0 red,
		18px -18px 0 -1px rgb(0, 0, 0), 18px -18px 0 blue;
	}

	.member-card:hover {
		transform: scale(1.05);
		background: white;
		border-top-right-radius: 80px;
		width: 110%;
		box-shadow: 
		0px 0px 0 1px rgb(255, 255, 255),
		10px -10px 0 -1px black,
		10px -10px rgb(255, 255, 255), 
		-10px 10px 0 -1px black,
		-10px 10px 0 rgb(255, 255, 255);
	}
	.member-card-head:hover {
		transform: scale(1.05);
		background: white;
		border-top-right-radius: 100px;
		width: 100%;
	}
	.member-card-head:hover .member-profile-image-head {
		height: 12rem;
		width: 12rem;
		top: 0px;
		left: -30px;
	}
}

@media screen and (max-width: 800px) {
	.member-profile-follow {
		color: #777;
		display: flex;
		justify-content: space-between;
		position: relative;
		bottom: -10px;
		transition: all 0.3s;
		opacity: 1;
	}
	.member-social-icon {
		padding-right: 0px;
	}
}
