.success-container .register-header .spacer {
	aspect-ratio: 1500/700;
	width: 100%;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	height: 100vh;
}

.success-container .register-header .layer1 {
	background-image: url("./../../assets/success_background.png");
}

.success-container .register-header .success-register-kdsh {
	position: absolute;
	top: 155px;
	left: 38%;
	font-size: 50px;
	font-weight: 900;
	color: rgb(0, 0, 0);
	padding: 5px 25px;
	text-wrap: nowrap;
}

.success-container .register-header .success-register-kdsh-desc {
	border: solid rgba(255, 255, 255, 0.178) 2px;
	padding: 25px;
	font-weight: 600;
	text-align: justify;
	position: absolute;
	top: 300px;
	font-size: 20px;
	line-height: 25px;
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
	background: rgba(255, 255, 255, 0.045);
	border-radius: 15px;
	margin: 0 200px;
	background-color: rgba(0, 0, 0, 0.434);
}

.success-container .register-header .success-register-kdsh-desc ul {
	list-style: none;
	text-decoration: none;
	color: aqua;
	transition: all 0.3s;
	width: max-content;
}

.success-container .register-header .success-register-kdsh-desc ul li:hover {
	text-shadow: cyan 0 0 8px;
}
.success-register-kdsh-desc p img{
	height: 40px;
	margin-right: 15px;
	cursor: pointer;
}

.kdsh2025_link {
	color: blue;
	border: solid 2px red;
}

.kdsh2025_success li{
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	margin-right: 15px;
}

.kdsh2025_success li a{
	margin: 0;
}	
.kdsh2025_success li a img{
	height: 40px;
	cursor: pointer;
}	

@media (max-width: 1285px) {
	.success-container .register-header .success-register-kdsh {
		top: 125px;
		left: 36%;
		padding: 5px 25px;
	}

	.success-container .register-header .success-register-kdsh-desc {
		padding: 20px;
		font-weight: 600;
		top: 265px;
		margin: 0 100px;
	}
}

@media (max-width: 1060px) {
	.success-container .register-header .success-register-kdsh {
		top: 120px;
		left: 34%;
		font-size: 45px;
		padding: 5px 25px;
	}

	.success-container .register-header .success-register-kdsh-desc {
		padding: 20px;
		font-weight: 600;
		top: 240px;
		margin: 0 100px;
	}
}

@media (max-width: 880px) {
	.success-container .register-header .success-register-kdsh {
		top: 110px;
		left: 34%;
		font-size: 45px;
		padding: 5px 25px;
	}

	.success-container .register-header .success-register-kdsh-desc {
		padding: 20px;
		font-weight: 600;
		top: 240px;
		margin: 0 90px;
	}
}

@media (max-width: 750px) {
	.success-container .register-header .success-register-kdsh {
		top: 110px;
		left: 32%;
		font-size: 45px;
		padding: 5px 25px;
	}

	.success-container .register-header .success-register-kdsh-desc {
		padding: 20px;
		font-weight: 600;
		top: 240px;
		margin: 0 90px;
	}
}

@media (max-width: 700px) {
	.success-container .register-header .success-register-kdsh {
		top: 110px;
		left: 28%;
		font-size: 45px;
		padding: 5px 25px;
	}

	.success-container .register-header .success-register-kdsh-desc {
		padding: 20px;
		font-weight: 600;
		top: 240px;
		margin: 0 50px;
		font-size: 18px;
	}
}

@media (max-width: 575px) {
	.success-container .register-header .success-register-kdsh {
		top: 100px;
		left: 24%;
		font-size: 45px;
		padding: 5px 25px;
	}

	.success-container .register-header .success-register-kdsh-desc {
		padding: 20px;
		font-weight: 600;
		top: 220px;
		margin: 0 40px;
		font-size: 16px;
	}
}

@media (max-width: 500px) {
	.success-container .register-header .success-register-kdsh {
		top: 100px;
		left: 20%;
		font-size: 45px;
		padding: 5px 25px;
	}
}

@media (max-width: 460px) {
	.success-container .register-header .success-register-kdsh {
		top: 100px;
		left: 22%;
		font-size: 35px;
		padding: 5px 25px;
	}

	.success-container .register-header .success-register-kdsh-desc {
		padding: 20px;
		font-weight: 600;
		top: 220px;
		margin: 0 30px;
		font-size: 16px;
	}
}

@media (max-width: 395px) {
	.success-container .register-header .success-register-kdsh {
		top: 100px;
		left: 18%;
		font-size: 35px;
		padding: 5px 25px;
	}

	.success-container .register-header .success-register-kdsh-desc {
		padding: 20px;
		font-weight: 600;
		top: 220px;
		margin: 0 20px;
		font-size: 16px;
	}
}
