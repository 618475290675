.alumni-members-list {
	display: flex;
	width: 92%;
	max-width: 62rem;
	margin: auto;
	flex-wrap: wrap;
	justify-content: center;
	background: rgba(255, 255, 255, 0.04);
	border-radius: 30px;
	padding-top: 25px;
	overflow: hidden;
	height: 51rem;
	overflow-y: scroll;
	backdrop-filter: blur(6px);
	padding-bottom: 65px;
	padding-top: 50px;
}

.alumni-members-list::-webkit-scrollbar{
	width: 0px;
}
