.resources-list-header {
  height: 30rem;
  padding-top: 10rem;
  background-size: cover;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
}

/* .resources-list-header-title {
  font-family: Poppins, sans-serif;
  font-size: 4rem;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
} */

.banner-KDSH {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: -150px;
  height: 500px;
  background-color: rgba(0, 0, 0, 0.7);
  justify-content: center;
  margin-bottom: 20px;
}

.banner-img {
  padding-right: 100px;
}

.banner-button {
  position: relative;
  overflow: hidden;
  z-index: 10;
  top: 25%;
  border-radius: 10px;
  height: 70px;
}

.register {
  font: 700 30px consolas;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  padding: 20px 60px;
  position: relative;
  transition: 0.2s;
  transform: scale(2);
  border-radius: 10px;
  width: auto;
  height: auto;
  top: 10px;
}

.register span {
  position: relative;
  z-index: 0;
  color: #fff;
  top: 10px;
}

.register .liquid {
  position: absolute;
  top: -80px;
  left: 0;
  width: 100%;
  height: 250px;
  background: #ff7272;
  box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.7);
  z-index: -20;
  transition: 0.6s;
  border-radius: 10px;
}

.register .liquid::after,
.register .liquid::before {
  position: absolute;
  content: "";
  width: 200%;
  height: 200%;
  top: 0;
  left: 0;
  transform: translate(-25%, -75%);
}

.register .liquid::after {
  border-radius: 45%;
  background: rgba(0, 0, 0, 1);
  box-shadow: 0 0 10px 5px #ff7272, inset 0 0 5px #ff7272;
  animation: animate 5s linear infinite;
  opacity: 0.8;
}

.register .liquid::before {
  border-radius: 40%;
  box-shadow: 0 0 10px rgba(26, 26, 26, 0.5),
      inset 0 0 5px rgba(26, 26, 26, 0.5);
  background: rgba(26, 26, 26, 0.5);

  animation: animate 7s linear infinite;
}

@keyframes animate {
  0% {
      transform: translate(-25%, -75%) rotate(0);
  }
  100% {
      transform: translate(-25%, -75%) rotate(360deg);
  }
}
.register:hover .liquid {
  top: -140px;
}

.register:hover {
  box-shadow: 0 0 5px #ff7272, inset 0 0 5px #ff7272;
  transition-delay: 0.2s;
}

@media screen and (max-width: 480px) {
  .banner-KDSH {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 300px;
    background-color: rgba(0, 0, 0, 0.7);
    justify-content: center;
    margin-top: 0px;
  }
  .banner-img img {
    height: 300px !important;
    width: 400px !important;
  }
  .register a {
    font: 700 20px consolas;
    padding: 20px 60px;
  }
  .banner-button {
    left: 20%;
  }
}

.bolding {
  background-color: rgb(160, 1, 1);
}