.content-container {
  margin: auto;
  width: 80%;
  position: relative;
  z-index: 15;
}

.content-pair {
  display: flex;
  margin-top: 10px;
  transition: all 0.4s;
}

.content-pair-text {
  width: 50%;
  font-size: 1.2rem;
  padding: 2rem;
  border-radius: 15px;
  backdrop-filter: blur(10px);
  background:rgba(255, 255, 255, 0.055);
  margin-bottom: 15px;
  transition: all 0.4s;
}

.content-pair-text hr{
  width: 100%;
  background: rgb(27, 27, 27);
}

.content-pair:hover .content-pair-text{
  background: white;
}

.content-pair-heading {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #ffffff;
}

.content-pair:hover .content-pair-heading{
  color: black;
}

.content-pair-word {
  text-transform: uppercase;
  transition: all 0.4s;
}

.content-pair:hover .content-pair-word{
  font-size: 2.3rem;
  color: white;
  -webkit-text-stroke: 2px rgb(0, 0, 0);
}

.content-pair-paragraph {
  color: #a5a9ae;
  /* font-family: "Merriweather", "Times New Roman", serif; */
  transition: all 0.4s;
}

.content-pair:hover .content-pair-paragraph{
  color: #6f6f6f;
}

.content-pair-graphics {
  width: 50%;
  padding: 1rem;  
}

.content-pair-graphics img {
  width: 100%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s;
}

.content-pair:hover .content-pair-graphics img{
  border: rgb(255, 255, 255) solid 2px;
  border-radius: 24px;
}

.content-mobile {
  display: none;
}

.content-pair-link {
  text-decoration: none;
}

@media (max-width: 800px) {
  .content-pair {
    display: block;
  }

  .content-pair-graphics {
    width: 100%;
  }

  .content-pair-text {
    width: 100%;
  }

  .content-mobile {
    display: block;
  }

  .content-nonmobile {
    display: none;
  }
}
