@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&family=Poppins:wght@300;400;700;900&family=Roboto:wght@300;400;700;900&family=Nunito:wght@300;400;700;900&display=swap");

* {
	/* margin: 0;
  padding: 0; */
	box-sizing: border-box;
}

html {
	overflow: auto;
}

body {
	overflow-x: hidden;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	font-family: "Montserrat", "Roboto", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

::-webkit-scrollbar {
	width: 0px;
}

::-webkit-scrollbar-track {
	background-color: transparent;
}


@media screen and (max-width: 1300px) {
	html {
		font-size: 75%;
	}
}

@media screen and (max-width: 600px) {
	html {
		font-size: 60%;
	}
}
