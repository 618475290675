.resources-list-header {
    opacity: 0;
    transition: opacity 1s;
  }
  
  .resources-list-header.show {
    opacity: 1;
  }
  
  .resources-list-header {
    height: 30rem;
    padding-top: 10rem;
    background-size: cover;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  }
  
  .resources-list-header-title {
    font-family: Poppins, sans-serif;
    font-size: 4rem;
    font-weight: 700;
    text-align: center;
    color: #ffffff;
  }
  
  .resources-list-header-subtitle {
    font-family: Poppins, sans-serif;
    font-size: 1.2rem;
    text-align: center;
    color: #ddd;
    width: 50%;
    margin: auto;
    min-width: 30rem;
  }
  