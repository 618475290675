.resources-list-card-wrapper {
	width: 25%;
	min-width: 20rem;
	margin: 2rem;
	display: flex;
}

.resources-list-card {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 1.5rem;
	transition: all 0.4s ease;
	border-radius: 15px;
	backdrop-filter: blur(10px);
	background: rgba(255, 255, 255, 0.042);
}

.resources-list-card-contain {
	display: flex;
}

.resources-list-card:hover {
	background: rgb(255, 255, 255, 1);
}

.resources-list-card:hover .resources-list-card-description{
  color: black;
}

.resources-list-card:hover .resources-list-card-title{
  color: black;
}

.resources-list-card:hover .resources-list-card-subtitle{
  color: black;
}

.resources-list-card-left {
	width: 15%;
}

.resources-list-card-icon {
	width: 2.5rem;
}

.resources-list-card-icon svg {
	width: 100%;
}

.resources-list-card-right {
	margin-left: 1rem;
}

.resources-list-card a {
	text-decoration: none;
	display: block;
}

.resources-list-card-title {
	font-weight: 700;
	font-size: 1.4rem;
	color: rgba(255, 255, 255, 0.909);
  transition: all 0.4s;
}

.resources-list-card-subtitle {
	color: #bfbfbf;
	margin-bottom: 1.7rem;
  transition: all 0.4s;
}

.resources-list-card-description {
	color: #bfbfbf;
	display: inline-block;
	position: relative;
  transition: all 0.4s;
	padding-bottom: 0.6rem;
}

/* .resources-list-card-description::after {
  border-bottom: 3px solid rgba(220, 66, 66);
  content: ' ';
  display: block;
  width: 70%;
  position: absolute;
  bottom: -2.5px;
  left: 0;
} */

.resources-list-card-button-outerMost:nth-child(3) {
	margin-top: auto;
	align-self: baseline;
	width: 100%;
}

.resources-list-card-button-outerMost {
	display: flex;
	justify-content: center;
}

.resources-list-card-button-outerMost a {
	width: 100%;
	display: flex;
	justify-content: center;
}

.resources-list-card-button {
	margin-top: 1rem;
	color: white;
	padding: 0.5rem 0.7rem;
	width: 70%;
	text-align: center;
	transition: all 0.2s ease;
	white-space: nowrap;
	border-radius: 25px;
	background-image: linear-gradient(
		to right,
		#4e3eff,
		#40dfe4,
		#30dd8a,
		#269660
	);
	background-size: 300% 100%;
	box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.5);
	font-weight: 600;
  transition: 0.3s;
	padding: 10px;
}

.resources-list-card-button:hover {
	background-position: 100% 0;
	text-shadow: 0 0 10px white;
}

@media screen and (max-width: 800px) {
	.resources-list-card-button {
		width: 100%;
		padding: 5px;
	}
}
